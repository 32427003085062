// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-chino-web-development-js": () => import("./../src/pages/landing/chino-web-development.js" /* webpackChunkName: "component---src-pages-landing-chino-web-development-js" */),
  "component---src-pages-privacy-js": () => import("./../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-projects-landing-page-example-js": () => import("./../src/pages/projects/landing-page-example.js" /* webpackChunkName: "component---src-pages-projects-landing-page-example-js" */),
  "component---src-pages-projects-machine-shop-example-js": () => import("./../src/pages/projects/machine-shop-example.js" /* webpackChunkName: "component---src-pages-projects-machine-shop-example-js" */),
  "component---src-pages-terms-js": () => import("./../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

